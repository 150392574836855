import { BlockContainer } from '@components/Blocks';
import ImageWithCaption from '@components/ImageWithCaption';
import { createBlock } from '@lib/features/blocks/blocks';

const ImageBlock = createBlock<'blocks_image_BlockType'>(({ image, caption }) => {
  return (
    <BlockContainer maxWidth="md">
      <ImageWithCaption
        imageProps={{
          ...image,
          ratio: 'landscape',
        }}
        caption={caption}
      />
    </BlockContainer>
  );
});

export default ImageBlock;
